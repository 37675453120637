import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { Box, Flex, InjectedModalProps, Modal, Text, ThemeSwitcher, Toggle, useMatchBreakpoints } from '@pancakeswap/uikit'
import { SUPPORT_ZAP, SUPPORT_CHART } from 'config/constants/supportChains'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useTheme from 'hooks/useTheme'
import { useCallback, useState } from 'react'
import {
  useExpertModeManager,
  useSubgraphHealthIndicatorManager,
  useUserExpertModeAcknowledgementShow,
  useUserSingleHopOnly,
  useZapModeManager,
  useAudioModeManager,
  useExchangeChartManager,
} from 'state/user/hooks'
import styled from 'styled-components'
import { useAppDispatch } from '../../../state'
import { muteAudio, unmuteAudio } from '../../../state/user/actions'
import QuestionHelper from '../../QuestionHelper'
import ExpertModal from './ExpertModal'
import GasSettings from './GasSettings'
import TransactionSettings from './TransactionSettings'
import { SettingsMode } from './types'

const ScrollableContainer = styled(Flex)`
  flex-direction: column;
  height: auto;
  ${({ theme }) => theme.mediaQueries.xs} {
    max-height: 90vh;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    max-height: none;
  }
`

export const withCustomOnDismiss =
  (Component) =>
    ({
      onDismiss,
      customOnDismiss,
      mode,
      ...props
    }: {
      onDismiss?: () => void
      customOnDismiss: () => void
      mode: SettingsMode
    }) => {
      const handleDismiss = useCallback(() => {
        onDismiss?.()
        if (customOnDismiss) {
          customOnDismiss()
        }
      }, [customOnDismiss, onDismiss])

      return <Component {...props} mode={mode} onDismiss={handleDismiss} />
    }

const SettingsModal: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss, mode }) => {
  const [showConfirmExpertModal, setShowConfirmExpertModal] = useState(false)
  const [, setShowExpertModeAcknowledgement] = useUserExpertModeAcknowledgementShow()
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()
  const [expertMode, toggleSetExpertMode] = useExpertModeManager()
  const { isMobile } = useMatchBreakpoints();
  const [userChartPreference, setUserChartPreference] = useExchangeChartManager(isMobile)
  const [audioPlay, toggleSetAudioMode] = useAudioModeManager()
  const [zapMode, toggleZapMode] = useZapModeManager()
  const [subgraphHealth, setSubgraphHealth] = useSubgraphHealthIndicatorManager()
  const { chainId } = useActiveChainId()
  const dispatch = useAppDispatch()

  const { t } = useTranslation()
  const { isDark, setTheme } = useTheme()

  if (showConfirmExpertModal) {
    return (
      <ExpertModal
        setShowConfirmExpertModal={setShowConfirmExpertModal}
        onDismiss={onDismiss}
        setShowExpertModeAcknowledgement={setShowExpertModeAcknowledgement}
      />
    )
  }

  return (
    <Modal
      title={t('Settings')}
      headerBackground="gradientCardHeader"
      onDismiss={onDismiss}
      style={{ maxWidth: '420px' }}
    >
      <ScrollableContainer>
        {mode === SettingsMode.GLOBAL && (
          <>
            <Flex pb="24px" flexDirection="column">
              <Text bold textTransform="uppercase" fontSize="18px" color="secondary" mb="24px">
                {t('Global')}
              </Text>
              <Flex justifyContent="space-between" mb="24px">
                <Text>{t('Dark mode')}</Text>
                <ThemeSwitcher isDark={isDark} toggleTheme={() => setTheme(isDark ? 'light' : 'dark')} />
              </Flex>
              <Flex justifyContent="space-between" alignItems="center" mb="24px">
                <Flex alignItems="center">
                  <Text>{t('Subgraph Health Indicator')}</Text>
                  <QuestionHelper
                    text={t(
                      'Turn on NFT market subgraph health indicator all the time. Default is to show the indicator only when the network is delayed',
                    )}
                    placement="top-start"
                    ml="4px"
                  />
                </Flex>
                <Toggle
                  id="toggle-subgraph-health-button"
                  checked={subgraphHealth}
                  scale="md"
                  onChange={() => {
                    setSubgraphHealth(!subgraphHealth)
                  }}
                />
              </Flex>
              { /* <GasSettings /> */}
            </Flex>
          </>
        )}
        {mode === SettingsMode.SWAP_LIQUIDITY && (
          <>
            <Flex pt="3px" flexDirection="column">
              <Text bold textTransform="uppercase" fontSize="18px" color="secondary" mb="10px">
                {t('Swaps & Liquidity')}
              </Text>
              <Flex justifyContent="space-between" alignItems="center" mb="24px">
                {chainId === ChainId.BSC && <GasSettings />}
              </Flex>
              <TransactionSettings />
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" mb="24px">
              <Flex alignItems="center">
                <Text>⚠️ {t('Expert Mode')}</Text>
                <QuestionHelper
                  text={
                    <Box>
                      <Text>
                        {t(
                          'Expert mode turns off the Confirm transaction prompt, and allows high slippage trades that often result in bad rates and lost funds.',
                        )}
                      </Text>
                    </Box>
                  }
                  placement="top-start"
                  ml="4px"
                />
              </Flex>
              <Toggle
                checked={expertMode}
                scale="md"
                onChange={() => {
                  if (!expertMode) {
                    setShowConfirmExpertModal(true)
                  } else {
                  toggleSetExpertMode()
                  }
                }}
              />
            </Flex>

            <Flex justifyContent="space-between" alignItems="center" mb="24px">
              <Flex alignItems="center">
                <Text>⛹️ { singleHopOnly ? 'Single Hop Only' : 'Allow Multi Hops' }</Text>
                <QuestionHelper
                  text={
                    <Box>
                      <Text>
                        {singleHopOnly ? 'Multi Hops Disabled, will only be able to trade using direct pairs only. Only use if you know what youre doing! (Not Recommended)' : 'Multi Hops Enabled, if there is not a direct pair for trade ChewySwap will find the best path to the output token (Recommended as Default)'}
                      </Text>
                    </Box>
                  }
                  placement="top-start"
                  ml="4px"
                />
              </Flex>
              <Toggle
                checked={!singleHopOnly}
                scale="md"
                onChange={() => {
                  setSingleHopOnly(!singleHopOnly)
                }}
              />
            </Flex>

            <Flex justifyContent="space-between" alignItems="center" mb="24px">
              <Flex alignItems="center">
                <Text>{ audioPlay ? '🔊' : '🔇' } {t('Mute Audio')}</Text>
                <QuestionHelper
                  text={
                    <Box>
                      <Text>
                        {t(
                          'Mute all swap sounds',
                        )}
                      </Text>
                    </Box>
                  }
                  placement="top-start"
                  ml="4px"
                />
              </Flex>
              <Toggle
                checked={!audioPlay}
                scale="md"
                onChange={() => {
                  if (audioPlay) {
                    dispatch(muteAudio())
                  } else {
                    dispatch(unmuteAudio())
                  }
                }}
              />
            </Flex>

            {SUPPORT_CHART.includes(chainId) && !isMobile && (
              <Flex justifyContent="space-between" alignItems="center" mb="24px">
              <Flex alignItems="center">
                <Text>📈 Show Chart by Default</Text>
                <QuestionHelper
                  text={
                    <Box>
                      <Text>
                        Display a price chart on swap page by default (Will take effect on next reload)
                      </Text>
                    </Box>
                  }
                  placement="top-start"
                  ml="4px"
                />
              </Flex>
              <Toggle
                checked={userChartPreference}
                scale="md"
                onChange={() => {
                  setUserChartPreference(!userChartPreference)
                }}
              />
            </Flex>
            )}


            {SUPPORT_ZAP.includes(chainId) && (
              <Flex justifyContent="space-between" alignItems="center" mb="24px">
                <Flex alignItems="center">
                  <Text>⚡️ {t('Zap (Beta)')}</Text>
                  <QuestionHelper
                    text={
                      <Box>
                        <Text>
                          {t(
                            'Zap enables simple liquidity provision. Add liquidity with one token and one click, without manual swapping or token balancing.',
                          )}
                        </Text>
                        <Text>
                          {t(
                            'If you experience any issue when adding or removing liquidity, please disable Zap and retry.',
                          )}
                        </Text>
                      </Box>
                    }
                    placement="top-start"
                    ml="4px"
                  />
                </Flex>
                <Toggle
                  checked={zapMode}
                  scale="md"
                  onChange={() => {
                    toggleZapMode(!zapMode)
                  }}
                />
              </Flex>

              
              
            )}
          </>
        )}
      </ScrollableContainer>
    </Modal>
  )
}

export default SettingsModal
