import { ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bsc_testnet',
  [ChainId.SHIBARIUM]: 'shibarium',
  [ChainId.DOGECHAIN]: 'dogechain',
}

const getTokenLogoURL = (token?: Token) => {
  if (token && mapping[token.chainId] && token.chainId === ChainId.SHIBARIUM) {
    return `/images/${ChainId.SHIBARIUM}/tokens/${token.address}.png`
  }
  if (token && mapping[token.chainId]) {
    return `/images/tokens/${token.address}.png`
  }
  return null
}

export default getTokenLogoURL
